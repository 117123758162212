.spot-selector-field
  &--label-wrap
    display: grid
    grid-template-columns: auto 1fr
    grid-template-areas: "input label" "description description"

    &_reversed
      grid-template-areas: "label input" "description description"

  &--input
    grid-area: input
    align-self: start
    padding-right: 0.5rem
    margin-bottom: 0
    display: flex

    &_reversed
      padding-left: 0.5rem
      padding-right: 0

      @media #{$spot-mq-mobile}
        // Push input to end of grid
        justify-self: end

  &--label
    grid-area: label
    margin-bottom: 0

    &_regular
      font-weight: normal

  &--description
    margin-bottom: 0

  &--label,
  &--description
    white-space: pre-line
    word-break: break-word
    word-wrap: break-word
